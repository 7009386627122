import {Outlet} from "react-router-dom";
import MyMenubar from "./MyMenubar";

export const UnprotectedRoute = () => {

  return (
    <div className="App">
      <MyMenubar/>
      <div className="flex justify-content-center flex-wrap">
        <Outlet/>
      </div>
    </div>
  );
};