import React from 'react';
import {Menubar} from 'primereact/menubar';
import {useTranslation} from "react-i18next";
import managr_logo from "./../images/Managr.png";

export default function MyMenubar() {
  const {t} = useTranslation();

  const items = [
    {
      label: t('menubar.schedule'),
      icon: 'pi pi-clock',
      url: '/'
    },
    {
      label: t('menubar.ranking'),
      icon: 'pi pi-bars',
      url: '/ranking'
    },
    // {
    //   label: t('menubar.game'),
    //   icon: 'pi pi-bullseye',
    //   url: '/game'
    // },
    // {
    //   label: t('menubar.team'),
    //   icon: 'pi pi-users',
    //   url: '/team'
    // }
  ];

  const start = <img alt="logo" src={managr_logo} height="40"
                     className="mr-2"></img>;
  const end = (
    <div className="flex align-items-center gap-2">
      <a href="/login" className="p-button font-bold">
        Sign In
      </a>
    </div>
  );

  return (
    <div className="card">
      <Menubar model={items} start={start} end={end}/>
    </div>
  )
}
