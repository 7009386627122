import React, {useState} from 'react';
import {Sidebar} from 'primereact/sidebar';
import {Avatar} from 'primereact/avatar';
import 'primeicons/primeicons.css';


import {useTranslation} from 'react-i18next';
import MyJudgeSidebar from "./MyJudgeSidebar";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";

let MySidebar = () => {
  const [visible, setVisible] = useState(true);

  const navigate = useNavigate();
  const {t} = useTranslation();
  const fullname = localStorage.getItem('fullname');
  const role = localStorage.getItem('role');
  const customHeader = (
    <div className="flex align-items-center gap-2">
      <Avatar image={'../images/avatars/old/robot-7.png'} shape="circle"/>
      <span className="font-bold">{fullname}</span>
    </div>
  );
  const customFooter = (
    <div className="flex align-items-center gap-2">
      <Button icon="pi pi-cog"/>
      <Button icon="pi pi-sign-out"/>

    </div>
  );

  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('fullname');
    localStorage.removeItem('role');
    localStorage.removeItem('id');

    window.location.reload();
  }

  if (role === 'ADMIN') {
    return (
      <div className="card flex justify-content-left">

        <Sidebar header={customHeader} modal={false} dismissable={false} visible={visible}
                 className="w-full md:w-18rem lg:w-18rem"
                 showCloseIcon={false} onHide={false}>
          <div className="flex flex-column h-full">
            <div className="overflow-y-auto">
              {/*<MyAdminSidebar/>*/}
              <MyJudgeSidebar/>
            </div>
            <div className="mt-auto">
              <hr className="mb-3 mx-3 border-top-1 border-none surface-border"/>
              <div className="flex flex-wrap justify-content-end gap-1 mb-4">
                {/*<Button icon="pi pi-cog" aria-label="Parameters" rounded text severity="help"/>*/}
                <Button icon="pi pi-sign-out" aria-label="SignOut" rounded text severity="help"
                        onClick={() => logout()}/>
              </div>
            </div>
          </div>
        </Sidebar>
      </div>
    )
  } else if (role === 'JUDGE') {
    return (
      <div className="card flex justify-content-left">

        <Sidebar header={customHeader} modal={false} dismissable={false} visible={visible}
                 className="w-full md:w-18rem lg:w-18rem"
                 showCloseIcon={false} onHide={false}>
          <div className="flex flex-column h-full">
            <div className="overflow-y-auto">
              <MyJudgeSidebar/>
            </div>
            <div className="mt-auto">
              <hr className="mb-3 mx-3 border-top-1 border-none surface-border"/>
              <div className="flex flex-wrap justify-content-end gap-1 mb-4">
                {/*<Button icon="pi pi-cog" aria-label="Parameters" rounded text severity="help"/>*/}
                <Button icon="pi pi-sign-out" aria-label="SignOut" rounded text severity="help"
                        onClick={() => logout()}/>
              </div>
            </div>
          </div>
        </Sidebar>
      </div>
    )
  }

}

export default MySidebar;