import React, {useEffect, useRef, useState} from 'react';
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {useTranslation} from "react-i18next";
import axios from "axios";


export default function Team() {
  let emptyTeam = {
    id: null,
    name: '',
    logo: '',
  };

  const [teams, setTeams] = useState(null);
  const [loading, setLoading] = useState(true);
  const [teamDialog, setTeamDialog] = useState(false);
  const [deleteTeamDialog, setDeleteTeamDialog] = useState(false);
  const [deleteTeamsDialog, setDeleteTeamsDialog] = useState(false);
  const [team, setTeam] = useState(emptyTeam);
  const [selectedTeams, setSelectedTeams] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const {t} = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const {data: response} = await axios.get('http://localhost:3001/teams');
      setTeams(response);
    } catch (error) {
      console.error(error)
    }
    setLoading(false);

  };

  const postData = async (team) => {
    try {
      await axios.post('http://localhost:3001/teams', {
        name: team.name,
        logo: team.logo,
      });

    } catch (error) {
      console.error(error)
    }
    setLoading(false);

  };

  const putData = async (team) => {
    try {
      await axios.put(`http://localhost:3001/teams/${team.id}`, {

        name: team.name,
        logo: team.logo,

      });
    } catch (error) {
      console.error(error)
    }
    setLoading(false);

  };

  const deleteData = async (id) => {
    try {
      await axios.delete(`http://localhost:3001/teams/${id}`);
    } catch (error) {
      console.error(error)
    }
    setLoading(false);

  };

  const openNew = () => {
    setTeam(emptyTeam);
    setSubmitted(false);
    setTeamDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setTeamDialog(false);
  };

  const hideDeleteTeamDialog = () => {
    setDeleteTeamDialog(false);
  };

  const hideDeleteTeamsDialog = () => {
    setDeleteTeamsDialog(false);
  };

  const saveTeam = () => {
    setSubmitted(true);

    if (team.name.trim() && team.logo.trim()) {
      let _teams = [...teams];
      let _team = {...team};
      setLoading(true);

      if (team.id) {
        putData(team).then(r => {
          const index = findIndexById(team.id);
          _teams[index] = _team;
        });
        toast.current.show({
          severity: 'success',
          summary: t('datatable.successful'),
          detail: t('team.team_updated'),
          life: 3000
        });
      } else {
        postData(team).then(r => {
          _team.id = createId();
          _teams.push(_team);
        });
        toast.current.show({
          severity: 'success',
          summary: t('datatable.successful'),
          detail: t('team.team_created'),
          life: 3000
        });
      }
      setTeams(_teams);
      setTeamDialog(false);
      setTeam(emptyTeam);
    }
  };

  const editTeam = (team) => {
    setTeam({...team});
    setTeamDialog(true);
  };

  const confirmDeleteTeam = (team) => {
    setTeam(team);
    setDeleteTeamDialog(true);
  };

  const deleteTeam = () => {
    let _teams = teams.filter((val) => val.id !== team.id);
    deleteData(team.id).then(r => {
      setTeams(_teams);
      setDeleteTeamDialog(false);
      setTeam(emptyTeam);

    });

    toast.current.show({
      severity: 'success',
      summary: t('datatable.successful'),
      detail: t('team.team_deleted'),
      life: 3000
    });

  };

  const findIndexById = (id) => {
    let index = -1;

    for (let i = 0; i < teams.length; i++) {
      if (teams[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const createId = () => {
    let id = '';
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return id;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteTeamsDialog(true);
  };

  const deleteSelectedTeam = () => {
    let _teams = teams.filter((val) => !selectedTeams.includes(val));
    for (let i = 0; i < selectedTeams.length; i++) {
      deleteData(selectedTeams[i].id).then(r => {
        setTeams(_teams);
        setDeleteTeamsDialog(false);
        setSelectedTeams(null);

      });
    }

    toast.current.show({
      severity: 'success',
      summary: t('datatable.successful'),
      detail: t('team.teams_deleted'),
      life: 3000
    });

  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    let _team = {...team};

    _team[`${name}`] = val;
    setTeam(_team);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button label={t('datatable.new')} icon="pi pi-plus" severity="success" onClick={openNew}/>
        <Button label={t('datatable.delete')} icon="pi pi-trash" severity="danger" onClick={confirmDeleteSelected}
                disabled={!selectedTeams || !selectedTeams.length}/>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return <Button label={t('datatable.export')} icon="pi pi-upload" className="p-button-help" onClick={exportCSV}/>;
  };


  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => editTeam(rowData)}/>
        <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteTeam(rowData)}/>
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">{t('team.manage')}</h4>
      <span className="p-input-icon-left">
                <i className="pi pi-search"/>
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)}
                           placeholder={t('datatable.search')}/>
            </span>
    </div>
  );
  const teamDialogFooter = (
    <React.Fragment>
      <Button label={t('datatable.cancel')} icon="pi pi-times" outlined onClick={hideDialog}/>
      <Button label={t('datatable.save')} icon="pi pi-check" onClick={saveTeam}/>
    </React.Fragment>
  );
  const deleteTeamDialogFooter = (
    <React.Fragment>
      <Button label={t('datatable.no')} icon="pi pi-times" outlined onClick={hideDeleteTeamDialog}/>
      <Button label={t('datatable.yes')} icon="pi pi-check" severity="danger" onClick={deleteTeam}/>
    </React.Fragment>
  );
  const deleteTeamsDialogFooter = (
    <React.Fragment>
      <Button label={t('datatable.no')} icon="pi pi-times" outlined onClick={hideDeleteTeamsDialog}/>
      <Button label={t('datatable.yes')} icon="pi pi-check" severity="danger" onClick={deleteSelectedTeam}/>
    </React.Fragment>
  );

  return (

    <div>
      {loading && <div>Loading</div>}
      {!loading && (
        <div>
          <Toast ref={toast}/>
          <div className="card">
            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

            <DataTable ref={dt} value={teams} selection={selectedTeams}
                       onSelectionChange={(e) => setSelectedTeams(e.value)}
                       dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                       currentPageReportTemplate={t('team.page_report')}
                       globalFilter={globalFilter} header={header}>
              <Column selectionMode="multiple" exportable={false}></Column>
              <Column field="name" header={t('team.name')} sortable style={{minWidth: '12rem'}}></Column>
              <Column field="logo" header={t('team.logo')} sortable style={{minWidth: '16rem'}}></Column>
              <Column body={actionBodyTemplate} exportable={false} style={{minWidth: '12rem'}}></Column>
            </DataTable>
          </div>

          <Dialog visible={teamDialog} style={{width: '32rem'}} breakpoints={{'960px': '75vw', '641px': '90vw'}}
                  header={t('team.team_details')} modal className="p-fluid" footer={teamDialogFooter}
                  onHide={hideDialog}>
            <div className="field">
              <label htmlFor="name" className="font-bold">
                {t('team.name')}
              </label>
              <InputText id="name" value={team.name} onChange={(e) => onInputChange(e, 'name')} required
                         autoFocus
                         className={classNames({'p-invalid': submitted && !team.name})}/>
              {submitted && !team.name && <small className="p-error">{t('team.name_required')}</small>}
            </div>
            <div className="field">
              <label htmlFor="logo" className="font-bold">
                {t('team.logo')}
              </label>
              <InputText id="logo" value={team.logo} onChange={(e) => onInputChange(e, 'logo')} required
                         autoFocus
                         className={classNames({'p-invalid': submitted && !team.logo})}/>
              {submitted && !team.logo && <small className="p-error">{t('team.logo_required')}</small>}
            </div>
          </Dialog>

          <Dialog visible={deleteTeamDialog} style={{width: '32rem'}} breakpoints={{'960px': '75vw', '641px': '90vw'}}
                  header={t('datatable.confirm')} modal footer={deleteTeamDialogFooter} onHide={hideDeleteTeamDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
              {team && (
                <span>
                            {t('datatable.delete_confirmation')} <b>{team.fullname}</b>?
                        </span>
              )}
            </div>
          </Dialog>

          <Dialog visible={deleteTeamsDialog} style={{width: '32rem'}} breakpoints={{'960px': '75vw', '641px': '90vw'}}
                  header={t('datatable.confirm')} modal footer={deleteTeamsDialogFooter} onHide={hideDeleteTeamsDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
              {team && <span>{t('team.multiple_delete_confirmation')}</span>}
            </div>
          </Dialog>
        </div>)
      }
    </div>
  );
}
        