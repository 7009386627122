import {StyleClass} from "primereact/styleclass";
import {Ripple} from "primereact/ripple";
import React, {useRef} from "react";
import {useTranslation} from "react-i18next";

let MyJudgeSidebar = () => {
  const btnRef3 = useRef(null);
  const btnRef4 = useRef(null);
  //const [evaluations, setUsers] = useState(null);

  const evaluations = [
    {id: 1, evaluation: "video", icon: "pi-camera", ref: '/video'},
    {id: 2, evaluation: "webcontent", icon: "pi-book", ref: '/webcontent'},
    {id: 3, evaluation: "webdesign", icon: "pi-palette", ref: '/webdesign'},
    {id: 4, evaluation: "tutorial", icon: "pi-wrench", ref: '/tutorial'},
    {id: 5, evaluation: "robotd", icon: "pi-android", ref: '/robotd'},
    {id: 6, evaluation: "robotc", icon: "pi-hammer", ref: '/robotc'},
    {id: 7, evaluation: "kiosk", icon: "pi-home", ref: '/kiosk'},
  ];

  const getEvaluationContent = evaluations => evaluations.map(item => (
    <li key={item.id}>
      <a href={item.ref} style={{textDecoration: "none"}}
         className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
        <i className={`pi ${item.icon} mr-2`}></i>
        <span className="font-medium">{t('sidebar.' + item.evaluation)}</span>
        <Ripple/>
      </a>
    </li>
  ));

  const {t} = useTranslation();


  return (
    <ul className="list-none p-2 m-0">
      <li>
        <StyleClass nodeRef={btnRef4} selector="@next" enterClassName="hidden" enterActiveClassName="slidedown"
                    leaveToClassName="hidden" leaveActiveClassName="slideup">
          <div ref={btnRef4}
               className="p-ripple p-3 flex align-items-center justify-content-between text-600 cursor-pointer">
            <span className="font-medium">{t('sidebar.judge')}</span>
            <i className="pi pi-chevron-down"></i>
            <Ripple/>
          </div>
        </StyleClass>
        <ul className="list-none p-0 m-0 overflow-hidden">
          <li>
            <StyleClass nodeRef={btnRef3} selector="@next" enterClassName="hidden"
                        enterActiveClassName="slidedown" leaveToClassName="hidden"
                        leaveActiveClassName="slideup">
              <a ref={btnRef3}
                 className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                <i className="pi pi-book mr-2"></i>
                <span className="font-medium">{t('sidebar.evaluation')}</span>
                <i className="pi pi-chevron-down ml-auto mr-1"></i>
                <Ripple/>
              </a>
            </StyleClass>
            <ul
              className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
              {getEvaluationContent(evaluations)}
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  )
}


export default MyJudgeSidebar;
