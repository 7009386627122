import React, {useState} from 'react';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";

import {Field, Form} from 'react-final-form';
import {Password} from "primereact/password";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import api from "../interceptors/Api";


const Login = () => {
  const [credentials, setCredentials] = useState({
    username: '',
    password: ''
  });
  const navigate = useNavigate();
  const {t} = useTranslation();


  const handleSubmit = async () => {
    try {
      const response = await api.post('auth/login', credentials);

      const {access_token, refresh_token, fullname, role, id} = response.data;

      // Store the tokens in localStorage or secure cookie for later use
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);
      localStorage.setItem('fullname', fullname);
      localStorage.setItem('role', role);
      localStorage.setItem('id', id);

      window.location.href = '/'
    } catch (error) {
      console.log(error)
    }
  };


  const validate = (data) => {
    let errors = {};

    if (!credentials.username) {
      errors.username = 'Email is required.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(credentials.username)) {
      errors.username = 'Invalid email address. E.g. example@email.com';
    }

    if (!credentials.password) {
      errors.password = 'Password is required.';
    }

    return errors;
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    let _credentials = {...credentials};

    _credentials[`${name}`] = val;
    setCredentials(_credentials);
  };

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };


  return (
    <div className="form-demo">
      <div className="flex justify-content-center">
        <div className="card">
          <h5 className="text-center">{t('login.login')}</h5>
          <Form onSubmit={handleSubmit}
                initialValues={{username: '', password: ''}}
                validate={validate} render={({handleSubmit}) => (
            <form onSubmit={handleSubmit} className="p-fluid">
              <Field name="username" render={({input, meta}) => (
                <div className="field">
                                    <span className="p-float-label p-input-icon-right">
                                        <i className="pi pi-envelope"/>
                                        <InputText id="username"  {...input} name={"username"}
                                                   value={credentials.username}
                                                   onChange={(e) => onInputChange(e, 'username')}
                                                   className={{'p-invalid': isFormFieldValid(meta)}}/>
                                        <label htmlFor="username"
                                               className={{'p-error': isFormFieldValid(meta)}}>{t('login.email')}</label>
                                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
              <Field name="password" render={({input, meta}) => (
                <div className="field">
                                    <span className="p-float-label">
                                        <Password id="password" {...input} toggleMask feedback={false}
                                                  name={"password"}
                                                  value={credentials.password}
                                                  onChange={(e) => onInputChange(e, 'password')}
                                                  className={{'p-invalid': isFormFieldValid(meta)}}/>
                                        <label htmlFor="password"
                                               className={{'p-error': isFormFieldValid(meta)}}>{t('login.password')}</label>
                                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>


              <Button type="submit" label={t('login.connect')} className="mt-2"/>
            </form>
          )}/>
        </div>
      </div>
    </div>
  );
};

export default Login;