import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    resources: {
      en: {
        translation: {
          sidebar: {
            administrator: 'ADMINISTRATOR',
            dashboard: 'Dashboard',
            user: 'User',
            competition: 'Competition',
            team: 'Team',
            evaluation: 'Evaluation',
            form: 'Form',
            submission: 'Submission',
            settings: 'Settings',
            judge: 'JUDGE',
            video: 'Video',
            webcontent: 'Website Content',
            webdesign: 'Website Design',
            tutorial: 'Tutorial',
            pool: 'Pool',
            robotd: 'Robot Design',
            robotc: 'Robot Construction',
            kiosk: 'Kiosk',
          },
          menubar: {
            schedule: 'Schedule',
            game: 'Games',
            team: 'Teams',
            ranking: 'Ranking'
          },
          datatable: {
            new: 'New',
            delete: 'Delete',
            yes: 'Yes',
            no: 'No',
            search: 'Search...',
            export: 'Export',
            confirm: 'Confirm',
            delete_confirmation: 'Do your really want to delete ',
            save: 'Save',
            cancel: 'Cancel',
            successful: 'Successful',
          },
          login: {
            login: 'Login',
            email: 'Email',
            password: 'Password',
            connect: 'Sign In'
          },
          user: {
            userlist: 'List of users',
            fullname: 'Fullname',
            email: 'Email',
            role: 'Role',
            active: 'Active',
            user_details: 'User Details',
            fullname_required: 'Fullname is required.',
            email_required: 'Email is required.',
            role_required: 'Role is required.',
            is_active: 'Enabled',
            select_role: 'Select a Role',
            administrator: 'Administrator',
            judge: 'Judge',
            multiple_delete_confirmation: 'Do your really want to delete the selected users?',
            manage: 'Manage users',
            page_report: 'Showing {first} to {last} of {totalRecords} users',
            user_deleted: 'User Deleted',
            users_deleted: 'Users Deleted',
            user_created: 'User Created',
            user_updated: 'User Updated',
          },
          competition: {
            manage: 'Manage competitions',
            name: 'Name',
            location: 'Location',
            submission_start: 'Submissions start',
            submission_deadline: 'Submission deadline',
            competition_details: 'Competition details',
            show_submission_link: 'Show submissions links',
            show_preliminary_ranking: 'Show preliminary ranking',
            show_final_ranking: 'Show final ranking',
            is_active: 'Enabled',
            page_report: 'Showing {first} to {last} of {totalRecords} competitions',
            multiple_delete_confirmation: 'Do your really want to delete the selected competitions?',
          },
          evaluation: {
            team: 'Team',
            choose_team: 'Choose a team',
            ranking: 'Ranking'
          },
          form: {
            link: 'Link',
            video: {
              title: 'Video Evaluation',
              ranking: 'Your ranking',
              header: 'Video',
              content: {
                criterion_1: 'Narrative Flow',
                criterion_2: 'Originality',
                criterion_3: 'Clarity',
              },
              technical: {
                criterion_1: 'Cinematography',
                criterion_2: 'Editing',
                criterion_3: 'Sound Quality',
              },
            },
            webcontent: {
              title: 'Website Content Evaluation',
              ranking: 'Your ranking',
              header: 'Website Content',
              content: {
                criterion_1: 'Relevance & Accuracy',
                criterion_2: 'Organization & Structure',
                criterion_3: 'Engagement & Readability ',
              },
            },
            webdesign: {
              title: 'Website Design Evaluation',
              ranking: 'Your ranking',
              header: 'Website Design',
              aesthetics: {
                criterion_1: 'Visual Appeal',
                criterion_2: 'Navigation & Usability ',
                criterion_3: 'Responsiveness & Compatibility',
              },
            },
            tutorial: {
              title: 'Tutorial Evaluation',
              ranking: 'Your ranking',
              header: 'Tutorial',
              content: {
                criterion_1: 'Content Accuracy & Depth',
                criterion_2: 'Clarity of Explanation',
                criterion_3: 'Engagement & Practical Application ',
              },
            },
            robotd: {
              title: 'Robot Design Evaluation',
              ranking: 'Your ranking',
              header: 'Robot Design',
              design_creativity: {
                criterion_1: 'Design Intent',
                criterion_2: 'Efficiency',
                criterion_3: 'Originality',
              },
              presentation: {
                criterion_1: 'Clarity',
                criterion_2: 'Organization',
                criterion_3: 'Response to questions',
              },
            },
            robotc: {
              title: 'Robot Construction Evaluation',
              ranking: 'Your ranking',
              header: 'Robot Construction',
              structure_maintenance: {
                criterion_1: 'Structural Integrity',
                criterion_2: 'Component Integration',
                criterion_3: 'Maintenance & Repair',
              },
              presentation: {
                criterion_1: 'Clarity',
                criterion_2: 'Organization',
                criterion_3: 'Response to questions',
              },
            },
            kiosk: {
              title: 'Kiosk Evaluation',
              ranking: 'Your ranking',
              header: 'Kiosk',
              construction: {
                criterion_1: 'Theme Alignment',
                criterion_2: 'Structural Design & Stability',
                criterion_3: 'User Accessibility & Functionality',
              },
              presentation: {
                criterion_1: 'Clarity',
                criterion_2: 'Organization',
                criterion_3: 'Response to questions',
              },
            },

          },
          schedule: {
            title: 'Schedule',
            game: 'Game',
            PRELIMINARY: 'Preliminaries',
            DRAFT: 'Drafts',
            WILDCARD: 'Wildcards',
            QUARTER: 'Quarter finals',
            SEMI: 'Semi finals',
            FINAL: 'Finals'
          },
          ranking: {
            title: 'Ranking'
          }

        },
      },
      fr: {
        translation: {
          sidebar: {
            administrator: 'ADMINISTRATEUR',
            dashboard: 'Tableau de bord',
            user: 'Utilisateur',
            competition: 'Compétition',
            team: 'Équipe',
            evaluation: 'Évaluation',
            form: 'Formulaire',
            submission: 'Soumission',
            settings: 'Paramètres',
            judge: 'JUGE',
            video: 'Vidéo',
            webcontent: 'Contenu site web',
            webdesign: 'Conception site web',
            tutorial: 'Tutoriel',
            pool: 'Groupement',
            robotd: 'Conception robot',
            robotc: 'Construction robot',
            kiosk: 'Kiosque',
          },
          menubar: {
            schedule: 'Horaire',
            game: 'Parties',
            team: 'Équipes',
            ranking: 'Classement'
          },
          login: {
            login: 'Connexion',
            email: 'Courriel',
            password: 'Mot de passe',
            connect: 'Se connecter'
          },
          datatable: {
            search: 'Recherche...',
            export: 'Exporter',
            new: 'Nouveau',
            delete: 'Supprimer',
            yes: 'Oui',
            no: 'Non',
            confirm: 'Confirmation',
            delete_confirmation: 'Êtes-vous sûr de vouloir supprimer ',
            save: 'Sauvegarder',
            cancel: 'Annuler',
            successful: 'Réussi',
          },
          user: {
            userlist: 'Liste des utilisateurs',
            fullname: 'Nom complet',
            email: 'Courriel',
            role: 'Rôle',
            active: 'Actif',
            user_details: 'Détails de l\'utilisateur',
            fullname_required: 'Nom complet requis.',
            email_required: 'Courriel requis.',
            role_required: 'Rôle requis.',
            is_active: 'Actif',
            select_role: 'Choisir un rôle',
            administrator: 'Administrateur',
            judge: 'Juge',
            multiple_delete_confirmation: 'Êtes-vous sûr de vouloir supprimer les utilisateurs sélectionnés?',
            manage: 'Gérer les utilisateurs',
            page_report: 'Affiche {first} à {last} de {totalRecords} utilisateurs',
            user_deleted: 'Utilisateur supprimé',
            users_deleted: 'Utilisateurs supprimés',
            user_created: 'Utilisateur créé',
            user_updated: 'Utilisateur mis à jour',
          },
          competition: {
            manage: 'Gérer les compétitions',
            name: 'Nom',
            location: 'Emplacement',
            submission_start: 'Début des remises',
            submission_deadline: 'Date limite des remises',
            competition_details: 'Détails de la compétition',
            show_submission_link: 'Afficher les liens des remises',
            show_preliminary_ranking: 'Afficher les classements préliminaires',
            show_final_ranking: 'Afficher les classements finaux',
            is_active: 'Actif',
            page_report: 'Affiche {first} à {last} de {totalRecords} compétitions',
            multiple_delete_confirmation: 'Êtes-vous sûr de vouloir supprimer les compétitions sélectionnés?',
            division_1: 'Division 1',
            division_2: 'Division 2',
          },
          evaluation: {
            team: 'Équipe',
            choose_team: 'Choisir une équipe',
            ranking: 'Classement'
          },
          form: {
            link: 'Lien',
            video: {
              title: 'Évaluation de la vidéo',
              ranking: 'Votre classement',
              header: 'Vidéo',
              content: {
                criterion_1: 'Flux Narratif',
                criterion_2: 'Originalité',
                criterion_3: 'Clarté',
              },
              technical: {
                criterion_1: 'Cadrage (Cinématographie)',
                criterion_2: 'Montage',
                criterion_3: 'Qualité du son',
              },
            },
            webcontent: {
              title: 'Évaluation du contenu du site web',
              ranking: 'Votre classement',
              header: 'Contenu du site web',
              content: {
                criterion_1: 'Pertinence et Précision',
                criterion_2: 'Organisation et Structure',
                criterion_3: 'Engagement et Lisibilité',
              },
            },
            webdesign: {
              title: 'Évaluation de la conception du site web',
              ranking: 'Votre classement',
              header: 'Conception du site web',
              aesthetics: {
                criterion_1: 'Attrait Visuel',
                criterion_2: 'Navigation et Utilisabilité',
                criterion_3: 'Réactivité et Compatibilité',
              }
            },
            tutorial: {
              title: 'Évaluation du tutoriel',
              ranking: 'Votre classement',
              header: 'Tutoriel',
              content: {
                criterion_1: 'Exactitude et Profondeur du contenu ',
                criterion_2: 'Clarté des explications',
                criterion_3: 'Engagement et applications pratiques',
              },
            },
            robotd: {
              title: 'Évaluation de la conception du robot',
              ranking: 'Votre classement',
              header: 'Conception du robot',
              design_creativity: {
                criterion_1: 'Intention de conception',
                criterion_2: 'Efficacité',
                criterion_3: 'Originalité',
              },
              presentation: {
                criterion_1: 'Clarté ',
                criterion_2: 'Organisation',
                criterion_3: 'Réponses aux questions',
              },
            },
            robotc: {
              title: 'Évaluation de la construction du robot',
              ranking: 'Votre classement',
              header: 'Construction du robot',
              structure_maintenance: {
                criterion_1: 'Solidité et Durabilité',
                criterion_2: 'Fonctionnalité et Performance',
                criterion_3: 'Maintenance et Réparation',
              },
              presentation: {
                criterion_1: 'Clarté',
                criterion_2: 'Organisation',
                criterion_3: 'Réponses aux questions',
              },
            },
            kiosk: {
              title: 'Évaluation du kiosque',
              ranking: 'Votre classement',
              header: 'Kiosque',
              construction: {
                criterion_1: 'Alignement avec le thème',
                criterion_2: 'Conception structurelle et Stabilité',
                criterion_3: 'Accessibilité et Fonctionnalité',
              },
              presentation: {
                criterion_1: 'Clarté',
                criterion_2: 'Organisation',
                criterion_3: 'Réponses aux questions',
              },
            },
          },
          schedule: {
            title: 'Horaire',
            game: 'Partie',
            PRELIMINARY: 'Préliminaires',
            DRAFT: 'Repêchages',
            WILDCARD: 'Wildcards',
            QUARTER: 'Quart de finals',
            SEMI: 'Demi-finales',
            FINAL: 'Finales'
          },
          ranking: {
            title: 'Classement'
          }
        },
      }
    }
  });

export default i18n;