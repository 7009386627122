import React, {useEffect, useRef, useState} from 'react';
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {Calendar} from 'primereact/calendar';
import {Dialog} from 'primereact/dialog';
import {RadioButton} from 'primereact/radiobutton';
import {InputText} from 'primereact/inputtext';
import {PickList} from 'primereact/picklist';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {ToggleButton} from "primereact/togglebutton";
import moment from 'moment'


export default function Competition() {
  let emptyCompetition = {
    id: null,
    name: '',
    show_submission_link: false,
    show_preliminary_ranking: false,
    show_final_ranking: false,
    submission_start: Date.now(),
    submission_deadline: Date.now(),
    is_active: 1
  };

  const [competitions, setCompetitions] = useState(null);
  const [teams, setTeams] = useState(null);
  const [loading, setLoading] = useState(true);
  const [competitionDialog, setCompetitionDialog] = useState(false);
  const [deleteCompetitionDialog, setDeleteCompetitionDialog] = useState(false);
  const [deleteCompetitionsDialog, setDeleteCompetitionsDialog] = useState(false);
  const [competitorsDialog, setCompetitorsDialog] = useState(false);
  const [competition, setCompetition] = useState(emptyCompetition);
  const [selectedCompetitions, setSelectedCompetitions] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const {t} = useTranslation();

  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const {data: competitions} = await axios.get('http://localhost:3001/competitions');
      setCompetitions(competitions);
      const {data: teams} = await axios.get('http://localhost:3001/teams');
      setSource(teams);

    } catch (error) {
      console.error(error)
    }
    setLoading(false);

  };

  const postData = async (competition) => {
    try {
      await axios.post('http://localhost:3001/competitions', {
        name: competition.name,
        location: competition.location,
        submission_start: competition.submission_start,
        submission_deadline: competition.submission_deadline,
        show_submission_link: competition.show_submission_link,
        show_preliminary_ranking: competition.show_preliminary_ranking,
        show_final_ranking: competition.show_final_ranking,
        is_active: competition.is_active
      });

    } catch (error) {
      console.error(error)
    }
    setLoading(false);

  };

  const putData = async (competition) => {
    try {
      await axios.put(`http://localhost:3001/competitions/${competition.id}`, {
        name: competition.name,
        location: competition.location,
        submission_start: competition.submission_start,
        submission_deadline: competition.submission_deadline,
        show_submission_link: competition.show_submission_link,
        show_preliminary_ranking: competition.show_preliminary_ranking,
        show_final_ranking: competition.show_final_ranking,
        is_active: competition.is_active
      });
    } catch (error) {
      console.error(error)
    }
    setLoading(false);

  };

  const deleteData = async (id) => {
    try {
      await axios.delete(`http://localhost:3001/competitions/${id}`);
    } catch (error) {
      console.error(error)
    }
    setLoading(false);

  };

  const onChange = (event) => {
    setSource(event.source);
    setTarget(event.target);
  };

  const openNew = () => {
    setCompetition(emptyCompetition);
    setSubmitted(false);
    setCompetitionDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setCompetitionDialog(false);
  };

  const hideDeleteCompetitionDialog = () => {
    setDeleteCompetitionDialog(false);
  };

  const hideDeleteCompetitionsDialog = () => {
    setDeleteCompetitionsDialog(false);
  };

  const hideCompetitorsDialog = () => {
    setCompetitorsDialog(false);
  };

  const saveCompetitor = () => {

  }
  const saveCompetition = () => {
    setSubmitted(true);

    if (competition.name.trim() && competition.location.trim()
      && competition.submission_start && competition.submission_deadline) {
      let _competitions = [...competitions];
      let _competition = {...competition};
      setLoading(true);

      if (competition.id) {
        putData(competition).then(r => {
          const index = findIndexById(competition.id);

          _competitions[index] = _competition;
        });

        toast.current.show({
          severity: 'success',
          summary: t('datatable.successful'),
          detail: t('competition.product_updated'),
          life: 3000
        });
      } else {
        postData(competition).then(r => {
          _competition.id = createId();
          _competitions.push(_competition);
        });

        toast.current.show({
          severity: 'success',
          summary: t('datatable.successful'),
          detail: t('competition.product_created'),
          life: 3000
        });
      }

      setCompetitions(_competitions);
      setCompetitionDialog(false);
      setCompetition(emptyCompetition);
    }
  };

  const editCompetition = (competition) => {
    setCompetition({...competition});
    setCompetitionDialog(true);
  };

  const editCompetitor = (competition) => {
    setCompetition({...competition});
    setCompetitorsDialog(true);
  };

  const confirmDeleteCompetition = (competition) => {
    setCompetition(competition);
    setDeleteCompetitionDialog(true);
  };

  const deleteCompetition = () => {
    let _competitions = competitions.filter((val) => val.id !== competition.id);

    deleteData(competition.id).then(r => {
      setCompetitions(_competitions);
      setDeleteCompetitionDialog(false);
      setCompetition(emptyCompetition);

    });

    toast.current.show({
      severity: 'success',
      summary: t('datatable.successful'),
      detail: t('competition.product_deleted'),
      life: 3000
    });

  };

  const findIndexById = (id) => {
    let index = -1;

    for (let i = 0; i < competitions.length; i++) {
      if (competitions[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const createId = () => {
    let id = '';
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return id;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteCompetitionsDialog(true);
  };

  const deleteSelectedCompetition = () => {
    let _competitions = competitions.filter((val) => !selectedCompetitions.includes(val));

    for (let i = 0; i < selectedCompetitions.length; i++) {
      deleteData(selectedCompetitions[i].id).then(r => {
        setCompetitions(_competitions);
        setDeleteCompetitionsDialog(false);
        setSelectedCompetitions(null);

      });
    }
    toast.current.show({
      severity: 'success',
      summary: t('datatable.successful'),
      detail: t('competition.products_deleted'),
      life: 3000
    });


  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    let _competition = {...competition};
    console.log(e.target)
    _competition[`${name}`] = val;

    setCompetition(_competition);
  };

  const setChecked = (e, name) => {
    let _competition = {...competition};

    _competition[`${name}`] = e.target.value;
    setCompetition(_competition);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button label={t('datatable.new')} icon="pi pi-plus" severity="success" onClick={openNew}/>
        <Button label={t('datatable.delete')} icon="pi pi-trash" severity="danger" onClick={confirmDeleteSelected}
                disabled={!selectedCompetitions || !selectedCompetitions.length}/>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return <Button label={t('datatable.export')} icon="pi pi-upload" className="p-button-help" onClick={exportCSV}/>;
  };

  const actionBodyDateStart = (rowData) => {
    return moment(rowData.submission_start).format('YYYY-MM-DD HH:mm:ss');
  }

  const actionBodyDateEnd = (rowData) => {
    return moment(rowData.submission_deadline).format('YYYY-MM-DD HH:mm:ss');
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-users" rounded outlined className="mr-2" onClick={() => editCompetitor(rowData)}/>
        <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => editCompetition(rowData)}/>
        <Button icon="pi pi-trash" rounded outlined severity="danger"
                onClick={() => confirmDeleteCompetition(rowData)}/>
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">{t('competition.manage')}</h4>
      <span className="p-input-icon-left">
                <i className="pi pi-search"/>
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)}
                           placeholder={t('datatable.search')}/>
            </span>
    </div>
  );
  const competitionDialogFooter = (
    <React.Fragment>
      <Button label={t('datatable.cancel')} icon="pi pi-times" outlined onClick={hideDialog}/>
      <Button label={t('datatable.save')} icon="pi pi-check" onClick={saveCompetition}/>
    </React.Fragment>
  );

  const competitorsDialogFooter = (
    <React.Fragment>
      <Button label={t('datatable.cancel')} icon="pi pi-times" outlined onClick={hideCompetitorsDialog}/>
      <Button label={t('datatable.save')} icon="pi pi-check" onClick={saveCompetitor}/>
    </React.Fragment>
  );
  const deleteCompetitionDialogFooter = (
    <React.Fragment>
      <Button label={t('datatable.no')} icon="pi pi-times" outlined onClick={hideDeleteCompetitionDialog}/>
      <Button label={t('datatable.yes')} icon="pi pi-check" severity="danger" onClick={deleteCompetition}/>
    </React.Fragment>
  );
  const deleteCompetitionsDialogFooter = (
    <React.Fragment>
      <Button label={t('datatable.no')} icon="pi pi-times" outlined onClick={hideDeleteCompetitionsDialog}/>
      <Button label={t('datatable.yes')} icon="pi pi-check" severity="danger" onClick={deleteSelectedCompetition}/>
    </React.Fragment>
  );

  const itemTemplate = (item) => {
    return (
      <div className="flex flex-wrap p-2 align-items-center gap-3">
        <span className="font-bold">{item.name}</span>
        <div className="flex flex-wrap gap-3">
          <div className="flex align-items-center">
            <RadioButton inputId="division1" name="ONE" value="ONE" onChange={(e) => item.division = e.value}
                         checked={item.division === 'ONE'}/>
            <label htmlFor="division1" className="ml-2">{t('competition.division_1')}</label>
          </div>
          <div className="flex align-items-center">
            <RadioButton inputId="division2" name="TWO" value="TWO" onChange={(e) => item.division = e.value}
                         checked={item.division === 'TWO'}/>
            <label htmlFor="division2" className="ml-2">{t('competition.division_2')}</label>
          </div>
        </div>
      </div>
    );
  };

  return (

    <div>
      {loading && <div>Loading</div>}
      {!loading && (
        <div>
          <Toast ref={toast}/>
          <div className="card">
            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

            <DataTable ref={dt} value={competitions} selection={selectedCompetitions}
                       onSelectionChange={(e) => setSelectedCompetitions(e.value)}
                       dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                       currentPageReportTemplate={t('competition.page_report')}
                       globalFilter={globalFilter} header={header}>
              <Column selectionMode="multiple" exportable={false}></Column>
              <Column field="name" header={t('competition.name')} sortable style={{minWidth: '12rem'}}></Column>
              <Column field="location" header={t('competition.location')} sortable style={{minWidth: '16rem'}}></Column>
              <Column field="submission_start" header={t('competition.submission_start')} sortable
                      body={actionBodyDateStart}
                      style={{minWidth: '10rem'}}></Column>
              <Column field="submission_deadline" header={t('competition.submission_deadline')} sortable
                      body={actionBodyDateEnd}
                      style={{minWidth: '10rem'}}></Column>
              <Column body={actionBodyTemplate} exportable={false} style={{minWidth: '12rem'}}></Column>
            </DataTable>
          </div>

          <Dialog visible={competitionDialog} style={{width: '40rem'}} breakpoints={{'960px': '75vw', '641px': '90vw'}}
                  header={t('competition.competition_details')} modal className="p-fluid"
                  footer={competitionDialogFooter}
                  onHide={hideDialog}>
            <div className="field">
              <label htmlFor="name" className="font-bold">
                {t('competition.name')}
              </label>
              <InputText id="name" value={competition.name} onChange={(e) => onInputChange(e, 'name')}
                         required
                         autoFocus
                         className={classNames({'p-invalid': submitted && !competition.name})}/>
              {submitted && !competition.name &&
                <small className="p-error">{t('competition.name_required')}</small>}
            </div>
            <div className="field">
              <label htmlFor="location" className="font-bold">
                {t('competition.location')}
              </label>
              <InputText id="location" value={competition.location} onChange={(e) => onInputChange(e, 'location')}
                         required
                         className={classNames({'p-invalid': submitted && !competition.location})}/>
              {submitted && !competition.location &&
                <small className="p-error">{t('competition.location_required')}</small>}
            </div>

            <div className="formgrid grid">
              <div className="field col">
                <label className="font-bold">
                  {t('competition.submission_start')}
                </label>
                <Calendar id="submission_start" value={new Date(competition.submission_start)}
                          onChange={(e) => onInputChange(e, 'submission_start')} required
                          showTime showSeconds hourFormat="24" dateFormat="yy-mm-dd"
                          className={classNames({'p-invalid': submitted && !competition.submission_start})}/>
                {submitted && !competition.submission_start &&
                  <small className="p-error">{t('competition.submission_start_required')}</small>}
              </div>
              <div className="field col">
                <label className="font-bold">
                  {t('competition.submission_deadline')}
                </label>
                <Calendar id="submission_deadline" value={new Date(competition.submission_deadline)}
                          onChange={(e) => onInputChange(e, 'submission_deadline')} required
                          showTime showSeconds hourFormat="24" dateFormat="yy-mm-dd"
                          className={classNames({'p-invalid': submitted && !competition.submission_deadline})}/>
                {submitted && !competition.submission_deadline &&
                  <small className="p-error">{t('competition.submission_deadline_required')}</small>}
              </div>
            </div>

            <div className="formgrid grid">
              <div className="field col-6">
                <label className="font-bold">
                  {t('competition.show_submission_link')}
                </label>
                <ToggleButton onLabel={t('datatable.yes')} offLabel={t('datatable.no')} id="show_submission_link"
                              checked={competition.show_submission_link}
                              onChange={(e) => setChecked(e, 'show_submission_link')}/>
              </div>
              <div className="field col-6">
                <label className="font-bold">
                  {t('competition.is_active')}
                </label>
                <ToggleButton onLabel={t('datatable.yes')} offLabel={t('datatable.no')} id="is_active"
                              checked={competition.is_active}
                              onChange={(e) => setChecked(e, 'is_active')}/>
              </div>
            </div>

            <div className="formgrid grid">
              <div className="field col-6">
                <label className="font-bold">
                  {t('competition.show_preliminary_ranking')}
                </label>
                <ToggleButton onLabel={t('datatable.yes')} offLabel={t('datatable.no')} id="show_preliminary_ranking"
                              checked={competition.show_preliminary_ranking}
                              onChange={(e) => setChecked(e, 'show_preliminary_ranking')}/>
              </div>
              <div className="field col-6">
                <label className="font-bold">
                  {t('competition.show_final_ranking')}
                </label>
                <ToggleButton onLabel={t('datatable.yes')} offLabel={t('datatable.no')} id="show_final_ranking"
                              checked={competition.show_final_ranking}
                              onChange={(e) => setChecked(e, 'show_final_ranking')}/>
              </div>
            </div>
          </Dialog>

          <Dialog visible={competitorsDialog} style={{width: '60rem'}}
                  breakpoints={{'960px': '75vw', '641px': '90vw'}}
                  header={t('datatable.confirm')} modal footer={competitorsDialogFooter}
                  onHide={hideCompetitorsDialog}>
            <div className="card">
              <PickList dataKey="id" source={source} target={target} onChange={onChange} itemTemplate={itemTemplate}
                        filter filterBy="name" breakpoint="1280px"
                        sourceHeader="Available" targetHeader="Selected" sourceStyle={{height: '24rem'}}
                        targetStyle={{height: '24rem'}}
                        sourceFilterPlaceholder="Search by name" targetFilterPlaceholder="Search by name"/>
            </div>
          </Dialog>

          <Dialog visible={deleteCompetitionDialog} style={{width: '32rem'}}
                  breakpoints={{'960px': '75vw', '641px': '90vw'}}
                  header={t('datatable.confirm')} modal footer={deleteCompetitionDialogFooter}
                  onHide={hideDeleteCompetitionDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
              {competition && (
                <span>
                            {t('datatable.delete_confirmation')} <b>{competition.fullname}</b>?
                        </span>
              )}
            </div>
          </Dialog>

          <Dialog visible={deleteCompetitionsDialog} style={{width: '32rem'}}
                  breakpoints={{'960px': '75vw', '641px': '90vw'}}
                  header={t('datatable.confirm')} modal footer={deleteCompetitionsDialogFooter}
                  onHide={hideDeleteCompetitionsDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
              {competition && <span>{t('competition.multiple_delete_confirmation')}</span>}
            </div>
          </Dialog>
        </div>)
      }
    </div>
  );
}
        