import React, {useEffect, useState} from 'react';
import {DataScroller} from 'primereact/datascroller';
import {Card} from 'primereact/card';
import api from "../interceptors/Api";
import 'moment-timezone';
import {useTranslation} from "react-i18next";

export default function Schedule() {
  const [heats, setHeats] = useState([]);

  const {t} = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (props, id) => {
    try {
      const {data: heats} = await api.get(`heats`, {});
      console.log(heats.result)
      setHeats(heats.result);

    } catch (error) {
      console.error(error)
    }
  };


  const getColor = (color) => {
    switch (color) {
      case 'BLUE':
        return '#157ACA';

      case 'YELLOW':
        return '#FDB02C';

      default:
        return null;
    }
  };

  const getTeam = (teams) => {
    let content = [];
    if (teams !== null)
      for (let i = 0; i < teams.length; i++) {
        const team = teams[i];
        for (let j = 0; j < team.heat_competitors.length; j++) {
          const competitor = team.heat_competitors[j];
          content.push(
            <div className="grid m-1">
              <div className="col-2 p-0">
                <div className="flex justify-content-center flex-wrap"
                     style={{minHeight: "100%", minWidth: "100%"}}>
                  <div
                    className="flex align-items-center justify-content-center">
                    <p>
                      <img className="w-7" src={`./images/teams/${competitor.team_logo}`} alt={''}/>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-8 p-0">
                <div className="flex justify-content-center flex-wrap"
                     style={{minHeight: "100%", minWidth: "100%"}}>
                  <div
                    className="flex align-items-center justify-content-center">
                    <p className="text-center text-sm sm:text-sm md:text-lg lg:text-xl"
                       style={{color: getColor(team.team_color)}}>{competitor.team_name}</p>
                  </div>
                </div>
              </div>
              <div className="col-2 p-0">
                <div className="flex justify-content-center flex-wrap"
                     style={{minHeight: "100%", minWidth: "100%"}}>
                  <div
                    className="flex align-items-center justify-content-center">
                    <p
                      className="text-sm sm:text-sm md:text-lg lg:text-xl"> {JSON.parse(competitor.game_parameters).score}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        }

      }
    return content;
  }

  const itemTemplate = (data) => {
    return (
      <Card className="m-2">
        <div className="grid nested-grid">
          <div className="col-8 p-0">

            {getTeam(data.heat_teams)}

          </div>
          <div className="col-4 p-0">
            <div className="flex justify-content-center flex-wrap"
                 style={{minHeight: "100%", minWidth: "100%"}}>

              <div className="flex flex-column">
                <div
                  className="flex align-items-center justify-content-center border-round m-2">
                  <p className="text-center text-lg md:text-2xl lg:text-4xl">
                    {t('schedule.game')} {data.game_number}</p>
                </div>
                <div
                  className="flex align-items-center justify-content-center border-round m-2">
                  <p className="text-center text-sm md:text-lg">
                    {data.start_date}</p>
                </div>
                <div
                  className="flex align-items-center justify-content-center border-round m-2">
                  <p className="text-center text-sm md:text-lg">
                    {t('schedule.' + data.block)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>


    );
  };

  return (
    <div className="flex flex-column align-items-center justify-content-center flex-wrap w-12">
      <div className=" flex align-items-center justify-content-center w-12 sm:w-12 md:w-10 lg:w-8 xl:w-6">
        <p className="text-center text-lg font-bold md:text-2xl lg:text-4xl">{t('schedule.title')}</p>
      </div>
      <div className="flex align-items-center justify-content-center w-12 sm:w-12 md:w-10 lg:w-8 xl:w-6">
        <DataScroller value={heats} itemTemplate={itemTemplate} rows={1000} buffer={0.4}/>
      </div>
    </div>

  )
}