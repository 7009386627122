import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {ProtectedRoute} from "./ProtectedRoute";
import {useAuth} from "../provider/AuthProvider";
import Logout from "./Logout";
import User from "./User";
import Competition from "./Competition";
import Team from "./Team";
import Evaluation from "./Evaluation";
import Pool from "./Pool";
import {UnprotectedRoute} from "./UnprotectedRoute";
import Login from "./Login";
import Schedule from "./Schedule";
import Scoreboard from "./Scoreboard";

const Routes = () => {
  const {access_token} = useAuth();

  // Define public routes accessible to all users
  const routesForPublic = [];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute/>, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/",
          element: <div>User Home Page For Judge</div>,
        },
        {
          path: "/user",
          element: <User/>,
        },
        {
          path: "/competition",
          element: <Competition/>,
        },
        {
          path: "/team",
          element: <Team/>,
        },
        {
          path: "/pool",
          element: <Pool/>,
        },
        {
          path: "/video",
          element: <Evaluation id={1}/>,
        },
        {
          path: "/webcontent",
          element: <Evaluation id={2}/>,
        },
        {
          path: "/webdesign",
          element: <Evaluation id={3}/>,
        },
        {
          path: "/tutorial",
          element: <Evaluation id={4}/>,
        },
        {
          path: "/robotd",
          element: <Evaluation id={5}/>,
        },
        {
          path: "/robotc",
          element: <Evaluation id={6}/>,
        },
        {
          path: "/kiosk",
          element: <Evaluation id={7}/>,
        },
        {
          path: "/logout",
          element: <Logout/>,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: "/",
      element: <UnprotectedRoute/>,
      children: [
        {
          path: "/",
          element: <Schedule/>,
        },
        {
          path: "/ranking",
          element: <Scoreboard/>,
        },
        {
          path: "/game",
          element: <div>Game Home Page</div>,
        },
        {
          path: "/team",
          element: <div>Team Home Page</div>,
        },
        {
          path: "/login",
          element: <Login/>
        }

      ],

    },
    {},

  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!access_token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router}/>;
};

export default Routes;