import React, {useEffect, useState} from 'react';
import {DataScroller} from 'primereact/datascroller';
import {Card} from 'primereact/card';
import api from "../interceptors/Api";
import 'moment-timezone';
import {useTranslation} from "react-i18next";

export default function Scoreboard() {
  const [teams, setTeams] = useState([]);

  const {t} = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (props, id) => {
    try {
      const {data: teams} = await api.get(`heats/scoreboard`, {});
      console.log(teams.result)
      setTeams(teams.result);

    } catch (error) {
      console.error(error)
    }
  };


  const itemTemplate = (data) => {
    return (
      <Card className="m-2">
        <div className="grid nested-grid">
          <div className="col-2 p-0">
            <div className="flex justify-content-center flex-wrap"
                 style={{minHeight: "100%", minWidth: "100%"}}>
              <div
                className="flex align-items-center justify-content-center">
                <p>
                  <img className="w-7" src={`./images/teams/${data.logo}`} alt={''}/>
                </p>
              </div>
            </div>
          </div>
          <div className="col-6 p-0">
            <p className="text-center text-lg md:text-2xl lg:text-4xl">
              {data.name}</p>
          </div>
          <div className="col-4 p-0">
            <div className="flex justify-content-center flex-wrap"
                 style={{minHeight: "100%", minWidth: "100%"}}>

              <div className="flex flex-column">
                <div
                  className="flex align-items-center justify-content-center border-round m-2">
                  <p className="text-center text-lg md:text-2xl lg:text-4xl">
                    {data.points}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>


    );
  };

  return (
    <div className="flex flex-column align-items-center justify-content-center flex-wrap w-12">
      <div className=" flex align-items-center justify-content-center w-12 sm:w-12 md:w-10 lg:w-8 xl:w-6">
        <p className="text-center text-lg font-bold md:text-2xl lg:text-4xl">{t('ranking.title')}</p>
      </div>
      <div className="flex align-items-center justify-content-center w-12 sm:w-12 md:w-10 lg:w-8 xl:w-6">
        <DataScroller value={teams} itemTemplate={itemTemplate} rows={1000} buffer={0.4}/>
      </div>
    </div>

  )
}