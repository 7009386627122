import React, {useEffect, useRef, useState} from 'react';
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {Dropdown} from 'primereact/dropdown';
import {ToggleButton} from 'primereact/togglebutton';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {useTranslation} from "react-i18next";
import api from "../interceptors/Api"


export default function User() {
  let emptyUser = {
    id: null,
    fullname: '',
    email: '',
    role: null,
    is_active: 1
  };

  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userDialog, setUserDialog] = useState(false);
  const [deleteUserDialog, setDeleteUserDialog] = useState(false);
  const [deleteUsersDialog, setDeleteUsersDialog] = useState(false);
  const [user, setUser] = useState(emptyUser);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const {t} = useTranslation();


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const {data: response} = await api.get('users');
      setUsers(response);
    } catch (error) {
      console.error(error)
    }
    setLoading(false);

  };

  const postData = async (user) => {
    try {
      await api.post('users', {
        fullname: user.fullname,
        email: user.email,
        role: user.role,
        is_active: user.is_active
      });

    } catch (error) {
      console.error(error)
    }
    setLoading(false);

  };

  const putData = async (user) => {
    try {
      await api.put(`users/${user.id}`, {

        fullname: user.fullname,
        email: user.email,
        role: user.role,
        is_active: user.is_active

      });
    } catch (error) {
      console.error(error)
    }
    setLoading(false);

  };

  const deleteData = async (id) => {
    try {
      await api().delete(`users/${id}`);
    } catch (error) {
      console.error(error)
    }
    setLoading(false);

  };

  const openNew = () => {
    setUser(emptyUser);
    setSubmitted(false);
    setUserDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setUserDialog(false);
  };

  const hideDeleteUserDialog = () => {
    setDeleteUserDialog(false);
  };

  const hideDeleteUsersDialog = () => {
    setDeleteUsersDialog(false);
  };

  const saveUser = () => {
    setSubmitted(true);

    if (user.fullname.trim() && user.email.trim()) {
      let _users = [...users];
      let _user = {...user};
      setLoading(true);

      if (user.id) {
        putData(user).then(r => {
          const index = findIndexById(user.id);
          _users[index] = _user;
        });
        toast.current.show({
          severity: 'success',
          summary: t('datatable.successful'),
          detail: t('user.user_updated'),
          life: 3000
        });
      } else {
        postData(user).then(r => {
          _user.id = createId();
          _users.push(_user);
        });
        toast.current.show({
          severity: 'success',
          summary: t('datatable.successful'),
          detail: t('user.user_created'),
          life: 3000
        });
      }
      setUsers(_users);
      setUserDialog(false);
      setUser(emptyUser);
    }
  };

  const editUser = (user) => {
    setUser({...user});
    setUserDialog(true);
  };

  const confirmDeleteUser = (user) => {
    setUser(user);
    setDeleteUserDialog(true);
  };

  const deleteUser = () => {
    let _users = users.filter((val) => val.id !== user.id);
    deleteData(user.id).then(r => {
      setUsers(_users);
      setDeleteUserDialog(false);
      setUser(emptyUser);

    });

    toast.current.show({
      severity: 'success',
      summary: t('datatable.successful'),
      detail: t('user.user_deleted'),
      life: 3000
    });

  };

  const findIndexById = (id) => {
    let index = -1;

    for (let i = 0; i < users.length; i++) {
      if (users[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const createId = () => {
    let id = '';
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return id;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteUsersDialog(true);
  };

  const deleteSelectedUser = () => {
    let _users = users.filter((val) => !selectedUsers.includes(val));
    for (let i = 0; i < selectedUsers.length; i++) {
      deleteData(selectedUsers[i].id).then(r => {
        setUsers(_users);
        setDeleteUsersDialog(false);
        setSelectedUsers(null);

      });
    }

    toast.current.show({
      severity: 'success',
      summary: t('datatable.successful'),
      detail: t('user.users_deleted'),
      life: 3000
    });

  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    let _user = {...user};

    _user[`${name}`] = val;
    setUser(_user);
  };
  const setChecked = (e, name) => {
    let _user = {...user};

    _user[`${name}`] = e.target.value;
    setUser(_user);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button label={t('datatable.new')} icon="pi pi-plus" severity="success" onClick={openNew}/>
        <Button label={t('datatable.delete')} icon="pi pi-trash" severity="danger" onClick={confirmDeleteSelected}
                disabled={!selectedUsers || !selectedUsers.length}/>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return <Button label={t('datatable.export')} icon="pi pi-upload" className="p-button-help" onClick={exportCSV}/>;
  };


  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => editUser(rowData)}/>
        <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteUser(rowData)}/>
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">{t('user.manage')}</h4>
      <span className="p-input-icon-left">
                <i className="pi pi-search"/>
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)}
                           placeholder={t('datatable.search')}/>
            </span>
    </div>
  );
  const userDialogFooter = (
    <React.Fragment>
      <Button label={t('datatable.cancel')} icon="pi pi-times" outlined onClick={hideDialog}/>
      <Button label={t('datatable.save')} icon="pi pi-check" onClick={saveUser}/>
    </React.Fragment>
  );
  const deleteUserDialogFooter = (
    <React.Fragment>
      <Button label={t('datatable.no')} icon="pi pi-times" outlined onClick={hideDeleteUserDialog}/>
      <Button label={t('datatable.yes')} icon="pi pi-check" severity="danger" onClick={deleteUser}/>
    </React.Fragment>
  );
  const deleteUsersDialogFooter = (
    <React.Fragment>
      <Button label={t('datatable.no')} icon="pi pi-times" outlined onClick={hideDeleteUsersDialog}/>
      <Button label={t('datatable.yes')} icon="pi pi-check" severity="danger" onClick={deleteSelectedUser}/>
    </React.Fragment>
  );

  return (

    <div>
      {loading && <div>Loading</div>}
      {!loading && (
        <div>
          <Toast ref={toast}/>
          <div className="card">
            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

            <DataTable ref={dt} value={users} selection={selectedUsers}
                       onSelectionChange={(e) => setSelectedUsers(e.value)}
                       dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                       currentPageReportTemplate={t('user.page_report')}
                       globalFilter={globalFilter} header={header}>
              <Column selectionMode="multiple" exportable={false}></Column>
              <Column field="fullname" header={t('user.fullname')} sortable style={{minWidth: '12rem'}}></Column>
              <Column field="email" header={t('user.email')} sortable style={{minWidth: '16rem'}}></Column>
              <Column field="role" header={t('user.role')} sortable style={{minWidth: '10rem'}}></Column>
              <Column body={actionBodyTemplate} exportable={false} style={{minWidth: '12rem'}}></Column>
            </DataTable>
          </div>

          <Dialog visible={userDialog} style={{width: '32rem'}} breakpoints={{'960px': '75vw', '641px': '90vw'}}
                  header={t('user.user_details')} modal className="p-fluid" footer={userDialogFooter}
                  onHide={hideDialog}>
            <div className="field">
              <label htmlFor="fullname" className="font-bold">
                {t('user.fullname')}
              </label>
              <InputText id="fullname" value={user.fullname} onChange={(e) => onInputChange(e, 'fullname')} required
                         autoFocus
                         className={classNames({'p-invalid': submitted && !user.fullname})}/>
              {submitted && !user.fullname && <small className="p-error">{t('user.fullname_required')}</small>}
            </div>
            <div className="field">
              <label htmlFor="email" className="font-bold">
                {t('user.email')}
              </label>
              <InputText id="email" value={user.email} onChange={(e) => onInputChange(e, 'email')} required
                         autoFocus
                         className={classNames({'p-invalid': submitted && !user.email})}/>
              {submitted && !user.email && <small className="p-error">{t('user.email_required')}</small>}
            </div>

            <div className="field">

            </div>

            <div className="formgrid grid">
              <div className="field col">
                <label className="font-bold">
                  {t('user.role')}
                </label>
                <Dropdown id="role" value={user.role} onChange={(e) => onInputChange(e, 'role')} required
                          options={[{name: t('user.administrator'), code: 'ADMIN'}, {
                            name: t('user.judge'),
                            code: 'JUDGE'
                          }]}
                          autoFocus placeholder={t('user.select_role')}
                          optionLabel="name" optionValue="code"
                          className={classNames({'p-invalid': submitted && !user.role})}/>
                {submitted && !user.role && <small className="p-error">{t('user.role_required')}</small>}
              </div>
              <div className="field col">
                <label className="font-bold">
                  {t('user.is_active')}
                </label>
                <ToggleButton id="is_active" onLabel={t('datatable.yes')} offLabel={t('datatable.no')}
                              checked={user.is_active}
                              onChange={(e) => setChecked(e, 'is_active')}/>
              </div>
            </div>
          </Dialog>

          <Dialog visible={deleteUserDialog} style={{width: '32rem'}} breakpoints={{'960px': '75vw', '641px': '90vw'}}
                  header={t('datatable.confirm')} modal footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
              {user && (
                <span>
                            {t('datatable.delete_confirmation')} <b>{user.fullname}</b>?
                        </span>
              )}
            </div>
          </Dialog>

          <Dialog visible={deleteUsersDialog} style={{width: '32rem'}} breakpoints={{'960px': '75vw', '641px': '90vw'}}
                  header={t('datatable.confirm')} modal footer={deleteUsersDialogFooter} onHide={hideDeleteUsersDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
              {user && <span>{t('user.multiple_delete_confirmation')}</span>}
            </div>
          </Dialog>
        </div>)
      }
    </div>
  );
}
        