import {Navigate, Outlet} from "react-router-dom";
import {useAuth} from "../provider/AuthProvider";
import MySidebar from "./MySidebar";

export const ProtectedRoute = () => {
  const {access_token} = useAuth();

  // Check if the user is authenticated
  if (!access_token) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/"/>;
  }

  // If authenticated, render the child routes
  return (
    <div className="App">
      <MySidebar/>
      <div className="flex flex-row-reverse flex-wrap">
        <div className="p-5" style={{width: 'calc(100% - 20rem)'}}>
          <Outlet/>
        </div>
      </div>
    </div>
  );
};