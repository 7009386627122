import React, {useEffect, useState} from 'react';
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";
import {Rating} from "primereact/rating";
import {Dialog} from "primereact/dialog";
import {useTranslation} from "react-i18next";
import {OrderList} from "primereact/orderlist";
import {Card} from "primereact/card";
import {Dropdown} from "primereact/dropdown";
import api from "../interceptors/Api";

const Evaluation = (props) => {
  const [pools, setPools] = useState([]);
  const [category, setCategory] = useState([]);
  const [form, setForm] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [formTitle, setFormTitle] = useState(null);
  const [competitorsDialog, setCompetitorsDialog] = useState(false);
  const id = localStorage.getItem('id');

  const {t} = useTranslation();

  useEffect(() => {
    fetchData(props, id);
  }, [props, id]);

  const fetchData = async (props, id) => {
    try {
      const {data: response} = await api.get(`evaluationpools/${id}`, {
        params: {
          category: props.id,
        }
      });
      console.log(response.result)
      setPools(response.result);
      if (response.category.length !== 0) {
        setCategory(response.category[0].category);
      }


      const {data: form} = await api.get(`forms/evaluation/${id}`, {
        params: {
          evaluation_id: null,
          form_id: props.id,
        }
      });
      console.log(form)
      setForm(form.result);
      setFormTitle(form.form)

    } catch (error) {
      console.error(error)
    }
  };

  const updateEvaluation = async (value) => {
    setSelectedTeam(value);

    const {data: form} = await api.get(`forms/evaluation/${id}`, {
      params: {
        evaluation_id: value.evaluation_id,
        form_id: props.id,
      }
    });
    setForm(form.result);
    setFormTitle(form.form)

  };
  const sendEvaluation = async () => {
    if (selectedTeam.evaluation_id !== null) {
      const json = JSON.stringify(form);

      await api.put(`forms/evaluation`, json, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } else {
      const json = JSON.stringify(form);

      await api.post(`forms/evaluation/${id}`, json, {
        params: {
          category: category,
          submission_id: selectedTeam.form_sub_id
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
    }
    window.location.reload();
  };


  const hideCompetitorsDialog = () => {
    setCompetitorsDialog(false);
  };

  const editCompetitor = (competition) => {
    setCompetitorsDialog(true);
  };
  const saveCompetitor = async () => {
    const json = JSON.stringify(pools);

    await api.put(`forms/ranking`, json, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    window.location.reload();
  };

  const onFieldRating = (e) => {
    let _form = [...form];
    _form[e.target.id].score = e.value;
    setForm(_form);
  }
  const onFieldComment = (e) => {
    let _form = [...form];
    _form[e.target.id].description = e.target.value;
    setForm(_form);
  }

  const onOrderListChange = (value) => {
    for (let i = 0; i < value.length; i++) {
      value[i].ranking = i + 1;
    }

    setPools(value);
  }

  const itemTemplate = (item) => {

    return (
      <div className="flex flex-wrap p-2 align-items-center gap-3">
        <div className="flex-1 flex flex-column gap-2 xl:mr-8">
          <span className="font-bold">{item.ranking} - {item.name}</span>
        </div>
        <span className="font-bold text-900">{item.score === null ? '?' : item.score} </span>

      </div>
    );
  };

  const competitorsDialogFooter = (
    <React.Fragment>
      <Button label={t('datatable.cancel')} icon="pi pi-times" outlined onClick={hideCompetitorsDialog}/>
      <Button label={t('datatable.save')} icon="pi pi-check"
              onClick={saveCompetitor}
      />
    </React.Fragment>
  );

  const getFormContent = form => {
    let content = [];
    if (form !== null)
      for (let i = 0; i < form.length; i++) {
        const item = form[i];
        content.push(
          <Card className={"col-12 m-3"} id={i}
                title={t('form.' + item.form + '.' + item.section + '.' + item.field)}>
            
            <Rating className="m-2" value={item.score} id={i}
                    onChange={(e) => onFieldRating(e)}
                    cancel={false}/>
            <div className={"flex justify-content-start flex-wrap"}>
              <InputTextarea className="80rem md:w-60rem m-2" value={item.description === null ? '' : item.description}
                             id={i}
                             onChange={(e) => onFieldComment(e)}
                             rows={3} cols={70}/>
            </div>

          </Card>
        );
      }
    return content;
  };


  return (
    <div className='block'>
      <div className="formgrid grid">
        <div className={"col-12 md:col-10 lg:col-6"}>
          <Card title={t('evaluation.team')}>
            <div className={"flex justify-content-start flex-wrap"}>
              <Dropdown value={selectedTeam} onChange={(e) => updateEvaluation(e.value)} options={pools}
                        optionLabel="name"
                        placeholder={t('evaluation.choose_team')} className="w-full md:w-14rem m-2"/>
              <Button className={"m-2"} label={t('form.link')}
                      visible={selectedTeam !== null && selectedTeam.url !== null}
                      onClick={() => window.open(selectedTeam.url, "_blank")}/>
            </div>
          </Card>
        </div>
        <div className={"col-12 mt-4"}>
          <div className={"flex justify-content-start flex-wrap"}>
            <h1 className="my-2">{t('form.' + formTitle + '.title')}</h1>
            <Button className="my-2 mx-4" type="submit" label={t('evaluation.ranking')}
                    onClick={() => editCompetitor()}/>
          </div>
        </div>

        {getFormContent(form)}


        <Button label={t('datatable.save')} className="mt-2" onClick={() => sendEvaluation()}/>

      </div>
      <Dialog visible={competitorsDialog} style={{width: '60rem'}}
              breakpoints={{'960px': '75vw', '641px': '90vw'}}
              header={t('form.' + formTitle + '.ranking')} modal footer={competitorsDialogFooter}
              onHide={hideCompetitorsDialog}>
        <div className="card">
          <OrderList dataKey="id" value={pools} onChange={(e) => onOrderListChange(e.value)} itemTemplate={itemTemplate}
                     header={t('form.' + formTitle + '.header')} dragdrop></OrderList>
        </div>
      </Dialog>

    </div>

  );
};

export default Evaluation;