import React from 'react';
import './App.css';
import AuthProvider from "./provider/AuthProvider";
import Routes from "./components/Routes";

let App = () => {
  // const [isAutheticated, setisAutheticated] = useState(false);
  //
  // function login() {
  //   setisAutheticated(true);
  //   console.log("loggedInUser:" + isAutheticated)
  // }
  //
  // function logout() {
  //   setisAutheticated(false);
  //   console.log("loggedInUser:" + isAutheticated)
  // }

  return (

    <AuthProvider>
      <Routes/>
    </AuthProvider>

    // <div className="App">
    //   <MySidebar/>
    //   <div className="flex flex-row-reverse flex-wrap">
    //     <div className="p-5" style={{width: 'calc(100% - 20rem)'}}>
    //
    //     </div>
    //   </div>
    //   <Routes>
    //     {/*<Route path="/" element={<SignIn />} />*/}
    //     <Route element={<ProtectedRoute/>}>
    //       <Route path="/user" element={<User/>}/>
    //       <Route path="/competition" element={<Competition/>}/>
    //       <Route path="/team" element={<Team/>}/>
    //     </Route>
    //   </Routes>
    // </div>
  );
}

export default App;
